import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/Header';
import AndroidLogo from '../img/android-logo.svg'; 
import AppleLogo from '../img/apple-logo.svg'; 


function Home() {
    return (
        <div className="flex flex-col min-h-screen bg-white text-gray-700">
            <div className="flex-grow">
                <Header />

                {/* Main Content */}
                <div className="text-center p-10">
                    <img src="dairect_logo.svg" alt="Dairect logo" className="mx-auto mb-4 w-44" />
                    <p className="text-2xl mb-10">bridging the gap between fans and creators</p>
                    <div className="flex justify-center space-x-10 mb-10">
                        <Link to="https://play.google.com/store/apps/details?id=com.dairect.dairect&pcampaignid=web_share" className="text-fan_blue hover:text-fan_blue-dark">
                            <img src="3 chat with-12-12.png" alt="Dairect for Android screenshot" className="mb-2" style={{width: '200px', height: 'auto'}}/>
                            <span className="block hover:underline"><img src={AndroidLogo} alt="Android logo" className="inline-block w-4 mr-02 mb-1" style={{width: '25px', height: 'auto'}}/> Dairect for Android</span>
                        </Link>
                        <Link to="https://apps.apple.com/au/app/dairect/id6503641089?platform=iphone" className="text-fan_blue hover:text-fan_blue-dark">
                            <img src="2 start monetizing-11-11.png" alt="Dairect for iPhone/iPad screenshot" className="mb-2" style={{width: '200px', height: 'auto'}}/>
                            <span className="block hover:underline"><img src={AppleLogo} alt="Apple logo" className="inline-block w-4 mr-0 mb-1" style={{width: '25px', height: 'auto'}}/> Dairect for iPhone</span>
                        </Link>
                    </div>
                </div>


                {/* Recent News 
                <div className="bg-gray-100 p-10">
                    <h2 className="text-3xl font-bold mb-6">Recent News</h2>
                    <div className="flex justify-between">
                        <div className="w-1/2 pr-4">
                            <img src="https://placehold.co/500x300" alt="Channel Appearance, Posts in Stories and More" className="mb-2" />
                            <h3 className="text-xl font-bold mb-2">Channel Appearance, Posts in Stories and More</h3>
                            <p className="mb-2">In Dairect's Christmas update, channels can set wallpapers, emoji statuses and profile logos, users can repost channel messages to stories...</p>
                            <span className="text-gray-500">Dec 23, 2023</span>
                        </div>
                        <div className="w-1/2 pl-4">
                            <img src="https://placehold.co/500x300" alt="Similar Channels, Reposting Stories, and 9 More Features" className="mb-2" />
                            <h3 className="text-xl font-bold mb-2">Similar Channels, Reposting Stories, and 9 More Features</h3>
                            <p className="mb-2">Today's update lets you find more channels on similar topics, repost stories from friends and channels, and add video messages to stories...</p>
                            <span className="text-gray-500">Nov 30, 2023</span>
                        </div>
                    </div>
                </div>
                */}

                {/* Why Dairect? 
                <div className="text-center p-10">
                    <h2 className="text-3xl font-bold mb-6">Why Dairect?</h2>
                    <div className="flex justify-around flex-wrap">
                        <Feature icon="fas fa-egg" title="Simple" description="Dairect is so simple you already know how to use it." />
                        <Feature icon="fas fa-sync-alt" title="Synced" description="Dairect lets you access your chats from multiple devices." />
                        <Feature icon="fas fa-rocket" title="Fast" description="Dairect delivers messages faster than any other application." />
                        <Feature icon="fas fa-bolt" title="Powerful" description="Dairect has no limits on the size of your media and chats." />
                        <Feature icon="fas fa-unlock-alt" title="Open" description="Dairect has an open API and source code free for everyone." />
                        <Feature icon="fas fa-shield-alt" title="Secure" description="Dairect keeps your messages safe from hacker attacks." />
                    </div>
                </div>
                */}
            </div>

            {/* Footer */}
            <div className="bg-gray-200 p-4 text-center text-sm">
                <div className="flex justify-around">
                    <div>
                        <h3 className="font-bold mb-2">Dairect</h3>
                        <p>Using AI for a direct communication between fans and creators. <br/>Guaranteed replies and engagement</p>
                    </div>
                    <div>
                        <h3 className="font-bold mb-2">About</h3>
                        {/*<Link to="/faq" className="block hover:underline">FAQ</Link>*/}
                        <Link to="/privacy" className="block hover:underline">Privacy</Link>
                        <Link to="/terms" className="block hover:underline">Terms and Conditions</Link>
                        <Link to="/eula" className="block hover:underline">EULA</Link>
                        {/*<a href="#" className="block hover:underline">News</a>*/}
                    </div>             
                    <div>
                        <h3 className="font-bold mb-2">Contact</h3>
                        <a href="mailto:info@dairect.me" className="block hover:underline">info@dairect.me</a>
                    </div>

                    {/*
                    <div>
                        <h3 className="font-bold mb-2">Mobile Apps</h3>
                        <a href="#" className="block hover:underline">iPhone/iPad</a>
                        <a href="#" className="block hover:underline">Android</a>
                    </div>
                    */}
                </div>
            </div>
        </div>
    );
}

{/*function Feature({ icon, title, description }) {
    return (
        <div className="feature">
            <i className={`${icon} feature-icon`}></i>
            <h3 className="feature-title">{title}</h3>
            <p className="feature-description">{description}</p>
        </div>
    );
}
*/}

export default Home;
