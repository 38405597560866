import React, { useRef } from 'react';
import Header from '../components/Header';

const EULA = () => {
    const applicationRef = useRef(null);
    const scopeOfLicenseRef = useRef(null);
    const technicalRequirementsRef = useRef(null);
    const noMaintenanceSupportRef = useRef(null);
    const userGeneratedContributionsRef = useRef(null);
    const contributionLicenseRef = useRef(null);
    const liabilityRef = useRef(null);
    const warrantyRef = useRef(null);
    const productClaimsRef = useRef(null);
    const legalComplianceRef = useRef(null);
    const contactInformationRef = useRef(null);
    const terminationRef = useRef(null);
    const thirdPartyTermsRef = useRef(null);
    const ipRightsRef = useRef(null);
    const applicableLawRef = useRef(null);
    const miscellaneousRef = useRef(null);

    const scrollToSection = (sectionRef) => {
        sectionRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <div className="bg-white text-gray-700">
            <Header />
            <div className="container mx-auto px-8 py-4">
                <div className="flex flex-col lg:flex-row lg:space-x-4">
                    <div className="flex-1">
                        <h1 className="text-3xl font-bold mb-6">End User License Agreement</h1>
                        <p className="mb-8">
                            Last updated: June 16, 2024
                        </p>

                        <h2 className="text-2xl font-bold mb-4" ref={applicationRef}>1. THE APPLICATION</h2>
                        <p className="mb-8">
                            "dairect" is a piece of software created to managing social media interactions and customized for mobile devices ("Devices"). It is used to allows creators to interact with followers.
                        </p>

                        <h2 className="text-2xl font-bold mb-4" ref={scopeOfLicenseRef}>2. SCOPE OF LICENSE</h2>
                        <p className="mb-8">
                            This License Agreement grants You, the End-User, a limited, non-exclusive, non-transferable license to use the dairect on any Devices that You own or control, as per the terms set forth herein. This license does not allow You to use the dairect on any device that You do not own or control, and You may not distribute or make the dairect available over a network where it could be used by multiple devices at the same time.
                        </p>

                        <h2 className="text-2xl font-bold mb-4" ref={technicalRequirementsRef}>3. TECHNICAL REQUIREMENTS</h2>
                        <p className="mb-8">
                            In order to use the dairect, your Devices must comply with minimum system requirements. These requirements include requires iOS 12 or higher, or Android 11 or higher, with at least 100MB of free memory. The performance of the dairect may vary depending on the configuration of your Devices.
                        </p>

                        <h2 className="text-2xl font-bold mb-4" ref={noMaintenanceSupportRef}>4. NO MAINTENANCE OR SUPPORT</h2>
                        <p className="mb-8">
                            The Licensor is not obligated, expressed or implied, to provide any maintenance, technical or other support for the dairect.
                            You and the End-User acknowledge that the Licensor has no obligation whatsoever to furnish any maintenance and support services with respect to the dairect.
                        </p>

                        <h2 className="text-2xl font-bold mb-4" ref={userGeneratedContributionsRef}>5. USER-GENERATED CONTRIBUTIONS</h2>
                        <p className="mb-8">
                            The dairect does not offer users to submit or post content. You may provide us with the opportunity to create, submit, post, display, transmit, perform, publish, distribute, or broadcast content and materials to us or in the dairect, including but not limited to text, writings, video, audio, photographs, graphics, comments, suggestions, or personal information or other material ("Contributions"). Contributions may be viewable by other users of the dairect and through third-party websites or applications. As such, any Contributions you transmit may be treated in accordance with the dairect Privacy Policy. When you create or make available any Contributions, you thereby represent and warrant that:
                            <ul>
                                <li>The creation, distribution, transmission, public display, or performance, and the accessing, downloading, or copying of your Contributions do not and will not infringe the proprietary rights, including but not limited to the copyright, patent, trademark, trade secret, or moral rights of any third party.</li>
                                <li>You are the creator and owner of or have the necessary licenses, rights, consents, releases, and permissions to use and to authorize us, the dairect, and other users of the dairect to use your Contributions in any manner contemplated by the dairect and this License Agreement.</li>
                                <li>You have the written consent, release, and/or permission of each and every identifiable individual person in your Contributions to use the name or likeness of each and every such identifiable individual person to enable inclusion and use of your Contributions in any manner contemplated by the dairect and this License Agreement.</li>
                                {/* Additional warranty points */}
                            </ul>
                        </p>

                        <h2 className="text-2xl font-bold mb-4" ref={contributionLicenseRef}>6. CONTRIBUTION LICENSE</h2>
                        <p className="mb-8">
                            You agree that we may access, store, process, and use any information and personal data that you provide following the terms of the Privacy Policy and your choices (including settings). By submitting suggestions or other feedback regarding the dairect, you agree that we can use and share such feedback for any purpose without compensation to you. We do not assert any ownership over your Contributions. You retain full ownership of all of your Contributions and any intellectual property rights or other proprietary rights associated with your Contributions. We are not liable for any statements or representations in your Contributions provided by you in any area in the dairect. You are solely responsible for your Contributions to the dairect and you expressly agree to exonerate us from any and all responsibility and to refrain from any legal action against us regarding your Contributions.
                        </p>

                        <h2 className="text-2xl font-bold mb-4" ref={liabilityRef}>7. LIABILITY</h2>
                        <p className="mb-8">
                            Details of liability related to the use of the dairect, including any limitations of liability that the Licensor may impose.
                        </p>

                        <h2 className="text-2xl font-bold mb-4" ref={warrantyRef}>8. WARRANTY</h2>
                        <p className="mb-8">
                            8.1 Licensor warrants that the dairect is free of spyware, trojan horses, viruses, or any other malware at the time of Your download. Licensor warrants that the dairect works as described in the user documentation.
                            <br /><br />
                            8.2 No warranty is provided for the dairect that is not executable on the device, that has been unauthorizedly modified, handled inappropriately or culpably, combined or installed with inappropriate hardware or software, used with inappropriate accessories, regardless if by Yourself or by third parties, or if there are any other reasons outside of dairect’s sphere of influence that affect the executability of the dairect.
                            <br /><br />
                            8.3 You are required to inspect the dairect immediately after installing it and notify info@dairect.me about issues discovered without delay by email provided in info@dairect.me. The defect report will be taken into consideration and further investigated if it has been emailed within a period of 30 days days after discovery.
                            <br /><br />
                            8.4 If we confirm that the dairect is defective, Licensor or a specified party reserves a choice to remedy the situation either by means of solving the defect or substitute delivery.
                            <br /><br />
                            8.5 In the event of any failure of the dairect to conform to any applicable warranty, You may notify the Services Store Operator, and Your dairect purchase price will be refunded to You. To the maximum extent permitted by applicable law, the Services Store Operator will have no other warranty obligation whatsoever with respect to the dairect, and any other losses, claims, damages, liabilities, expenses, and costs attributable to any negligence to adhere to any warranty.
                            <br /><br />
                            8.6 If the user is an entrepreneur, any claim based on faults expires after a statutory period of limitation amounting to twelve (12) months after the dairect was made available to the user. The statutory periods of limitation given by law apply for users who are consumers.
                        </p>

                        <h2 className="text-2xl font-bold mb-4" ref={productClaimsRef}>9. PRODUCT CLAIMS</h2>
                        <p className="mb-8">
                            dairect and the End-User acknowledge that dairect, and not the Services, is responsible for addressing any claims of the End-User or any third party relating to the dairect or the End-User's possession and/or use of that dairect, including, but not limited to:
                            <ol>
                                <li>Product liability claims;</li>
                                <li>Any claim that the dairect fails to conform to any applicable legal or regulatory requirement; and</li>
                                <li>Claims arising under consumer protection, privacy, or similar legislation.</li>
                            </ol>
                        </p>

                        <h2 className="text-2xl font-bold mb-4" ref={legalComplianceRef}>10. LEGAL COMPLIANCE</h2>
                        <p className="mb-8">
                            You represent and warrant that You are not located in a country that is subject to a US Government embargo, or that has been designated by the US Government as a "terrorist supporting" country; and that You are not listed on any US Government list of prohibited or restricted parties.
                        </p>

                        <h2 className="text-2xl font-bold mb-4" ref={contactInformationRef}>11. CONTACT INFORMATION</h2>
                        <p className="mb-8">
                            For general inquiries, complaints, questions or claims concerning the dairect, please contact:
                            <ul>
                                <li>info@dairect.me</li>

                            </ul>
                        </p>

                        <h2 className="text-2xl font-bold mb-4" ref={terminationRef}>12. TERMINATION</h2>
                        <p className="mb-8">
                            The license is valid until terminated by dairect or by You. Your rights under this license will terminate automatically and without notice from dairect if You fail to adhere to any term(s) of this license. Upon License termination, You shall stop all use of the dairect, and destroy all copies, full or partial, of the dairect.
                        </p>

                        <h2 className="text-2xl font-bold mb-4" ref={thirdPartyTermsRef}>13. THIRD-PARTY TERMS OF AGREEMENTS AND BENEFICIARY</h2>
                        <p className="mb-8">
                            dairect represents and warrants that it/he/she will comply with applicable third-party terms of agreement when using dairect. In accordance with Section 9 of the "Instructions for Minimum Terms of Developer's End-User License Agreement," subsidiaries shall be third-party beneficiaries of this End User License Agreement and — upon Your acceptance of the terms and conditions of this License Agreement, will have the right (and will be deemed to have accepted the right) to enforce this End User License Agreement against You as a third-party beneficiary thereof.
                        </p>

                        <h2 className="text-2xl font-bold mb-4" ref={ipRightsRef}>14. INTELLECTUAL PROPERTY RIGHTS</h2>
                        <p className="mb-8">
                            dairect and the End-User acknowledge that, in the event of any third-party claim that the dairect or the End-User's possession and use of that dairect infringes on the third party's intellectual property rights, dairect, and not the Services, will be solely responsible for the investigation, defense, settlement, and discharge of any such intellectual property infringement claims.
                        </p>

                        <h2 className="text-2xl font-bold mb-4" ref={applicableLawRef}>15. APPLICABLE LAW</h2>
                        <p className="mb-8">
                            This License Agreement is governed by the laws of Australia excluding its conflicts of law rules.
                        </p>

                        <h2 className="text-2xl font-bold mb-4" ref={miscellaneousRef}>16. MISCELLANEOUS</h2>
                        <p className="mb-8">
                            If any of the terms of this agreement should be or become invalid, the validity of the remaining provisions shall not be affected. Invalid terms will be replaced by valid ones formulated in a way that will achieve the primary purpose. Collateral agreements, changes, and amendments are only valid if laid down in writing. The preceding clause can only be waived in writing.
                        </p>
                    </div>


                    {/* Table of Contents */}
                    <div className="w-full lg:w-auto lg:flex-none lg:pt-6">
                        <div className="sticky top-4">
                            <h3 className="text-xl font-bold mb-2">Table of Contents</h3>
                            <ul className="list-none">
                                <li className="mb-1"><button onClick={() => scrollToSection(applicationRef)} className="text-blue-600 hover:underline">1. The Application</button></li>
                                <li className="mb-1"><button onClick={() => scrollToSection(scopeOfLicenseRef)} className="text-blue-600 hover:underline">2. Scope of License</button></li>
                                <li className="mb-1"><button onClick={() => scrollToSection(technicalRequirementsRef)} className="text-blue-600 hover:underline">3. Technical Requirements</button></li>
                                <li className="mb-1"><button onClick={() => scrollToSection(noMaintenanceSupportRef)} className="text-blue-600 hover:underline">4. No Maintenance or Support</button></li>
                                <li className="mb-1"><button onClick={() => scrollToSection(userGeneratedContributionsRef)} className="text-blue-600 hover:underline">5. User-Generated Contributions</button></li>
                                <li className="mb-1"><button onClick={() => scrollToSection(contributionLicenseRef)} className="text-blue-600 hover:underline">6. Contribution License</button></li>
                                <li className="mb-1"><button onClick={() => scrollToSection(liabilityRef)} className="text-blue-600 hover:underline">7. Liability</button></li>
                                <li className="mb-1"><button onClick={() => scrollToSection(warrantyRef)} className="text-blue-600 hover:underline">8. Warranty</button></li>
                                <li className="mb-1"><button onClick={() => scrollToSection(productClaimsRef)} className="text-blue-600 hover:underline">9. Product Claims</button></li>
                                <li className="mb-1"><button onClick={() => scrollToSection(legalComplianceRef)} className="text-blue-600 hover:underline">10. Legal Compliance</button></li>
                                <li className="mb-1"><button onClick={() => scrollToSection(contactInformationRef)} className="text-blue-600 hover:underline">11. info@dairect.me</button></li>
                                <li className="mb-1"><button onClick={() => scrollToSection(terminationRef)} className="text-blue-600 hover:underline">12. Termination</button></li>
                                <li className="mb-1"><button onClick={() => scrollToSection(thirdPartyTermsRef)} className="text-blue-600 hover:underline">13. Third-Party Terms of Agreements and Beneficiary</button></li>
                                <li className="mb-1"><button onClick={() => scrollToSection(ipRightsRef)} className="text-blue-600 hover:underline">14. Intellectual Property Rights</button></li>
                                <li className="mb-1"><button onClick={() => scrollToSection(applicableLawRef)} className="text-blue-600 hover:underline">15. Applicable Law</button></li>
                                <li className="mb-1"><button onClick={() => scrollToSection(miscellaneousRef)} className="text-blue-600 hover:underline">16. Miscellaneous</button></li>

                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EULA;
