import "./index.css";
import React from "react";
import ReactDOM from "react-dom/client";
import Site from "./site/Site";

document.addEventListener("DOMContentLoaded", () => {
  const root = ReactDOM.createRoot(document.getElementById("root"));
  root.render(
        <React.StrictMode>
          <Site />
        </React.StrictMode>
  );
});

