import React, { useState, useContext } from 'react';
import { useNavigate, Link } from "react-router-dom";
import { collection, doc, setDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { AuthContext } from '../context/AuthContext';

const Questionnaire = () => {
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();

  // Define the structure of your questions in sections
  const sections = [
  {
      title: 'Basic Information',
      questions: [
      { id: 'fullName', questionText: "What's your full name?" },
      { id: 'age', questionText: "How old are you?" },
      { id: 'living', questionText: "Where are you currently living?" },
      { id: 'jobTitle', questionText: "What's your job title?" },
      { id: 'educationLevel', questionText: "What's your highest level of education?" },
      ],
  },
  {
      title: 'Lifestyle & Interests',
      questions: [
      { id: 'favoriteHobby', questionText: "What's your favorite hobby?" },
      { id: 'relaxAfterWork', questionText: "How do you relax after a long day?" },
      { id: 'favoriteBookMovie', questionText: "What's your favorite book or movie?" },
      { id: 'travelEnjoyment', questionText: "Do you enjoy traveling, and where to?" },
      { id: 'skillToLearn', questionText: "What's one skill you'd like to learn?" },
      ],
  },
  {
      title: 'Work & Education',
      questions: [
      { id: 'favoriteSubject', questionText: "What subject did you enjoy most in school?" },
      { id: 'workPreference', questionText: "Do you prefer working from home or in an office?" },
      { id: 'careerAchievement', questionText: "What's a significant achievement in your career?" },
      { id: 'organizeWorkday', questionText: "How do you organize your workday?" },
      { id: 'careerGoal', questionText: "What's one career goal you have for the next year?" },
      ],
  },
  {
      title: 'Social & Relationships',
      questions: [
      { id: 'listenerTalker', questionText: "Are you more of a listener or a talker?" },
      { id: 'socializeFrequency', questionText: "How often do you socialize with friends?" },
      { id: 'weekendIdeal', questionText: "What's your ideal way to spend a weekend?" },
      { id: 'stayInTouch', questionText: "How do you stay in touch with distant friends or family?" },
      { id: 'valuedTrait', questionText: "What trait do you value most in a friend?" },
      ],
  },
  {
      title: 'Personality Insights',
      questions: [
      { id: 'birdType', questionText: "Are you an early bird or a night owl?" },
      { id: 'decisionsOn', questionText: "Do you make decisions more with your heart or your head?" },
      { id: 'adaptability', questionText: "Do you find it easy to adapt to new situations?" },
      { id: 'detailBigPicture', questionText: "Are you more detail-oriented or big-picture?" },
      { id: 'routineSpontaneity', questionText: "Do you prefer routine or spontaneity?" },
      ],
  },
  {
      title: 'MBTI-Style Preferences',
      questions: [
      { id: 'partyInteraction', questionText: "At a party, do you interact with many people or just a few friends?" },
      { id: 'factsIdeas', questionText: "Do facts or ideas interest you more?" },
      { id: 'disagreementFocus', questionText: "In a disagreement, do you prioritize harmony or truth?" },
      { id: 'weekendPlanning', questionText: "Do you plan your weekend or go with the flow?" },
      { id: 'learningPreference', questionText: "When learning, do you prefer hands-on experience or theoretical discussion?" },
      ],
  },
  ];

  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
  const [responses, setResponses] = useState({});

  const handlePrevious = () => {
      setCurrentSectionIndex(Math.max(currentSectionIndex - 1, 0));
  };

  const handleNext = () => {
      setCurrentSectionIndex(Math.min(currentSectionIndex + 1, sections.length - 1));
  };

  const handleInputChange = (questionId, value) => {
      setResponses({
          ...responses,
          [questionId]: value,
      });
  };

  const handleSubmit = async () => { 
      let questionnaireResponses = {};
  
      sections.forEach((section) => {
          let sectionResponses = {};
          section.questions.forEach((question) => {
              sectionResponses[question.id] = responses[question.id] || "No answer provided";
          });
          questionnaireResponses[section.title] = sectionResponses;
      });
  
      // Call backend function to perform the embedding of the questionnaire responses
      // TODO
  
      // Save the questionnaire responses JSON object to the database
      const userId = currentUser.uid;
      const userDocRef = doc(collection(db, "users"), userId);
      await setDoc(userDocRef, { questionnaire: questionnaireResponses }, { merge: true });
  
      // Redirect to the home page
      navigate("/thanks");
  };
  

    const currentSection = sections[currentSectionIndex];

  return (
    <div className="questionnaire">
      <h2>{currentSection.title}</h2>
      <form>
        {currentSection.questions.map((question) => (
          <div key={question.id} className="questionnaire-form-group">
            <label htmlFor={question.id}>{question.questionText}</label>
            <input
              type="text"
              id={question.id}
              value={responses[question.id] || ''}
              onChange={(e) => handleInputChange(question.id, e.target.value)}
              className="form-control"
            />
          </div>
        ))}
        <div className="questionnaire-navigation-buttons">
          {currentSectionIndex > 0 && (
            <button type="button" onClick={handlePrevious} className="questionnaire-btn-secondary">
              Previous
            </button>
          )}
          {currentSectionIndex < sections.length - 1 ? (
            <button type="button" onClick={handleNext} className="questionnaire-btn-primary">
              Next
            </button>
          ) : (
            <button type="button" onClick={handleSubmit} className="questionnaire-btn-success">
              Submit
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default Questionnaire;
