import React from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
    return (
        <div className="relative p-4 flex justify-between items-center mb-12 text-gray-700">
            <div className="flex space-x-4">
                <Link to="/home" className="hover:underline text-fan_blue">Home</Link>
                <Link to="/faq" className="hover:underline text-fan_blue">FAQ</Link>
            </div>
            <div className="flex items-center space-x-2">
                <a href="#" className="hover:underline text-creator_pink">EN</a>
            </div>
            <div className="absolute bottom-0 left-0 w-full" style={{ height: '1px' }} >
                <div className="w-full h-full bg-gradient-to-r from-fan_blue to-creator_pink"></div>
            </div>
        </div>
    );
};

export default Header;
