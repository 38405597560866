import React from 'react';
import Home from './pages/Home';
import Role from "./pages/Role";
import FanRegister from "./pages/FanRegister";
import Questionnaire from "./pages/Questionnaire";
import CreatorRegister from "./pages/CreatorRegister";
import Waitlist from "./pages/Waitlist";
import FAQ from "./pages/FAQ";
import Privacy from "./pages/Privacy";
import Thanks from "./pages/Thanks";
import Tos from "./pages/ToS";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "./style/main.scss";
import EULA from './pages/EULA';

function Site() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Navigate to="/home" replace />} />
                <Route path="/home" element={<Home />} />
                <Route path="/waitlist" element={<Waitlist/>} />
                <Route path="/register" element={<Role />} />
                <Route path="/fan-register" element={<FanRegister />} />
                <Route path="/creator-register" element={<CreatorRegister />} />
                <Route path="/questionnaire" element={<Questionnaire />} />
                <Route path="/thanks" element={<Thanks />} />
                <Route path="/faq" element={<FAQ />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/terms" element={<Tos />} />
                <Route path="/eula" element={<EULA />} />
                <Route path="*" element={<Navigate to="/home" replace />} />
            </Routes>
        </BrowserRouter>
    );
}

export default Site;
