import React from 'react';
import { useNavigate } from 'react-router-dom';

const Thanks = () => {
    const navigate = useNavigate();

    const handleHome = () => {
        navigate('/home');
    };

    return (
        <div className="thanks-container">
            
            <div className="thanks-content">
                <img src="dairect_logo.svg" alt="Dairect logo" className="mx-auto mb-4 w-44" />
                <h2>Thanks! You will be notified when the app is ready!</h2>
                <button onClick={handleHome} className="thanks-button">
                    Home
                </button>
            </div>
        </div>
    );
};

export default Thanks;
