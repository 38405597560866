import React from 'react';
import { useNavigate } from 'react-router-dom';

const Waitlist = () => {
    const navigate = useNavigate();

    const handleJoinWaitlist = () => {
        navigate('/register');
    };

    return (
        <div className="waitlist-container">
            <div className="waitlist-content">
                <h2>Our app is currently under development</h2>
                <button onClick={handleJoinWaitlist} className="waitlist-button">
                    Join the Waitlist
                </button>
            </div>
        </div>
    );
};

export default Waitlist;
