// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage"
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA6L4kabkG7b5ebqI5l1LhdQ-C_z-0ZZes",
  authDomain: "dairect-47.firebaseapp.com",
  databaseURL: "https://dairect-47-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "dairect-47",
  storageBucket: "dairect-47.appspot.com",
  messagingSenderId: "237117900122",
  appId: "1:237117900122:web:e54df5ef56b85046559f63",
  measurementId: "G-EZ7FXE814J"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const storage = getStorage(app)
export const db = getFirestore(app);
export const functions = getFunctions(app, 'us-central1');

// Check if everything is working fine
console.log(app.name);
console.log(auth);
console.log(storage);
console.log(db);
