import React, { useState } from 'react';
import Header from '../components/Header'; // Adjust the path as needed

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="mb-4 text-left">
      <button onClick={toggle} className="flex items-center font-bold">
        <span className="mr-2">{isOpen ? '−' : '+'}</span>
        {question}
      </button>
      {isOpen && <p className="mt-2">{answer}</p>}
    </div>
  );
};

const FAQ = () => {
  return (
    <div className="bg-white text-gray-700 flex flex-col">
        <Header />
        <div className="flex-1 p-4 flex flex-col items-center justify-center">        
            <h1 className="text-3xl font-bold mb-6">Frequently Asked Questions</h1>
            
            <div className="w-full max-w-xl">
                <h2 className="text-2xl font-bold mb-4">General Questions</h2>
                <FAQItem
                    question="What is Dairect?"
                    answer="Dairect is a chat app aimed to connect creators and fans."
                />
                <FAQItem
                    question="Why Dairect?"
                    answer="For creators, Dairect is a great way to reach more fans with the help of an AI trained on their personality, allowing a bigger reach and monetization. For fans, Dairect offers an exclusive and direct interaction with their favorite creators."
                />
                <FAQItem
                    question="How does it work?"
                    answer="Fans can purchase messages to start a conversation with their favorite creators. Creators can respond directly or use an AI bot trained on their personality to reach more fans efficiently."
                />

                <h2 className="text-2xl font-bold mb-4">Account Management</h2>
                <FAQItem
                    question="How do I reset my password?"
                    answer="To reset your password, go to the account settings in the app and click on 'Forgot Password?' Follow the instructions sent to your email to reset your password."
                />
                <FAQItem
                    question="Can I change my username?"
                    answer="Yes, you can change your username by going to the account settings in the app and clicking on section where you can edit your username."
                />
                <FAQItem
                    question="Where can I find my purchase history?"
                    answer="Your purchase history is available under the 'Orders' section in your account settings."
                />

                <h2 className="text-2xl font-bold mb-4">Additional Support</h2>
                {/* Suggested additional FAQ items */}
                <FAQItem
                    question="Is my personal information secure?"
                    answer="Yes, we prioritize user privacy and security. All personal information is encrypted and securely stored."
                />
                <FAQItem
                    question="How can I report a problem or give feedback?"
                    answer="To report a problem or provide feedback, please use the 'Contact' form available on our website or through the app."
                />
                <FAQItem
                    question="Are there any guidelines for user conduct?"
                    answer="Yes, we have a set of community guidelines that all users are expected to follow. These can be found in the 'Terms of Service' section on our website."
                />
                {/* Add more FAQ items here */}
            </div>
        </div>
    </div>
  );
};

export default FAQ;
