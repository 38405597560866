import { useNavigate } from "react-router-dom";
import { useState } from "react";

const Role = () => {
    const [err, setErr] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleRole = async (role) => {
        setLoading(true);

        try {
            if (role === "creator") {
                navigate("/creator-register");
            } else if (role === "fan") {
                navigate("/fan-register");
            } else {
                navigate("/");
            }

            setLoading(false); // Ensure loading is set to false upon successful execution
        } catch (err) {
            console.error(err); // Debug log
            setErr(true);
            setLoading(false); // Ensure loading is set to false if an error occurs
        }
    };

    // A lot to do here artistically: animated logo, animated text, colors choice...
    return (
        <div className="role-container">
            <div className="role-wrapper">
                {/*<h1>Dairect </h1>*/}
                <h2>Who are you?</h2>
                <div className="role-buttons-container">
                    <button className="role-creator-btn" onClick={() => handleRole("creator")}>Creator</button>
                    <button className="role-fan-btn" onClick={() => handleRole("fan")}>Fan</button>
                </div>
                {loading && <span>Creating account please wait...</span>}
                {err && <span>Something went wrong</span>}
            </div>
        </div>
    );
    
};

export default Role;
