import React, { useState } from "react";
import Add from "../img/addAvatar.png";
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { auth, db, storage } from "../../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { useNavigate, Link } from "react-router-dom";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";

const FanRegister = () => {
  const [err, setErr] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // Google sign up
  const handleGoogleSignUp = async () => {

    try{
      const provider = new GoogleAuthProvider();
      await signInWithPopup(auth, provider);
  
      // Check if user exists in database
      const user = auth.currentUser;
      const userRef = doc(db, "users", user.uid);
      const userSnap = await getDoc(userRef);
  
      if (!userSnap.exists()) {
        // If user does not exist, create user in database
        await setDoc(userRef, {
          uid: user.uid,
          displayName: user.displayName,
          email: user.email,
          photoURL: user.photoURL,
          role: "fan",
          online: false,
        });
  
        await setDoc(doc(db, "userChats", user.uid), {});
  
        navigate("/thanks");
      }
    } catch (err) {
      console.error(err); // Debug log
      setErr(true);
      setLoading(false); // Ensure loading is set to false if an error occurs
    }
  }
  

  //console.log("Current Loading State: ", loading); // Debug log
  //console.log("Current Error State: ", err); // Debug log

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const displayName = e.target[0].value;
    const email = e.target[1].value;
    const password = e.target[2].value;
    const file = e.target[3].files[0] || Add;
    const role = "fan";
    
    try {
      const res = await createUserWithEmailAndPassword(auth, email, password);
  
      const date = new Date().getTime();
      const storageRef = ref(storage, `${displayName + date}`);
  
      await uploadBytesResumable(storageRef, file).then(async () => {
        const downloadURL = await getDownloadURL(storageRef);

        await updateProfile(res.user, {
          displayName,
          photoURL: downloadURL,
        });
          
        await setDoc(doc(db, "users", res.user.uid), {
          uid: res.user.uid,
          displayName,
          email,
          photoURL: downloadURL,
          role, 
          online: false,
          orders: [],
        });
  
        await setDoc(doc(db, "userChats", res.user.uid), {});
  
        navigate("/thanks");

        setLoading(false); // Ensure loading is set to false upon successful execution
      });
    } catch (err) {
      console.error(err); // Debug log
      setErr(true);
      setLoading(false); // Ensure loading is set to false if an error occurs
    }
  };

  return (
    <div className="fan-register-form-container">
      <div className="fan-register-form-wrapper">
        <img src="dairect_logo.svg" alt="Dairect logo" className="mx-auto mb-4 w-44" />
        <p className="text-2xl mb-10">bridging the gap between fans and creators</p>
        <form onSubmit={handleSubmit}>
          <input required type="text" placeholder="username" />
          <input required type="email" placeholder="email" />
          <input required type="password" placeholder="password" />
          <input style={{ display: "none" }} type="file" id="file" />
          {/*<label htmlFor="file">
            <img src={Add} alt="" />
            <span>P Picture (TBM to user/creator home)</span>
  </label>*/}
          <button className="fan-register-signup" disabled={loading}>Sign up</button>
          <button className="fan-register-google" onClick={handleGoogleSignUp}>Sign up with Google</button>
        </form>
        {loading && <span>Creating account please wait...</span>}
        {err && <span>Something went wrong</span>}
      </div>
    </div>
  );
};

export default FanRegister;